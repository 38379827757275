@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
}

.rbt-aux .btn-close {
  pointer-events: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-sidebar {
  z-index: 1046;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  transition: 0.3s ease-in;
}

#sidebar-backdrop {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1045;
  transform: translateX(100vw);
  background-color: rgba(0, 0, 0, 0.1);
}

.app-sidebar.active+#sidebar-backdrop {
  width: 100vw;
  transform: translateX(0);
  background-color: rgba(0, 0, 0, 0.3);
}

.app-sidebar.active {
  transform: translateX(0);
}